import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import SquaresItem from './SquaresItem'

const SquaresTable = ({
  selectedSquares,
  onSquareClick,
  user,
  rsvpId,
  disableClick,
  showWinners,
  showNumbers,
  removeTeams,
  teamTopName,
  teamTopClassName,
  teamLeftClassName,
  teamLeftName,
  topRowIndexes,
  leftRowIndexes,
  quarter = 1,
  winners = [null, null, null, null]
}) => {
  const [squareSize, setSquareSize] = useState(40)
  const containerRef = useRef(null)
  const currentUser = rsvpId || user?.uid

  const topRowKeys = topRowIndexes || Array.from({ length: 10 })
  const leftRowKeys = leftRowIndexes ?? Array.from({ length: 10 })

  useEffect(() => {
    const updateSquareSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth

        const newSize = containerWidth / 10 - 5
        setSquareSize(newSize)
      }
    }

    updateSquareSize()

    window.addEventListener('resize', updateSquareSize)
    return () => {
      window.removeEventListener('resize', updateSquareSize)
    }
  }, [])

  return (
    <div className="w-full flex items-center justify-center px-1">
      {/* This div wraps the squares */}
      <div className="flex flex-col w-full">
        {/* This div add the top line */}
        {!removeTeams && (
          <div
            className={`flex gap-4 flex-row w-full items-center pl-12 pr-2 ${!!showNumbers && `mb-4`}`}
          >
            <div
              className={twMerge(
                `flex flex-1 h-2 bg-white rounded-full border-1 border-black`,
                teamTopClassName
              )}
            />
            <div className="flex items-center justify-center">
              <span className="bg-white sm:text-[16px] text-[14px]">
                {teamTopName}
              </span>
            </div>
            <div
              className={twMerge(
                `flex flex-1 h-2 bg-white rounded-full border-1 border-black`,
                teamTopClassName
              )}
            />
          </div>
        )}

        <div className="flex overflow-visible w-full">
          {/* This div add the left line */}
          {!removeTeams && (
            <div
              className={`flex flex-col gap-5 h-100 w-1 mt-4 pt-6 ${!!showNumbers && 'mr-6'}`}
            >
              <div
                className={twMerge(
                  `flex flex-1 w-2 rounded-full bg-black border-1 border-black`,
                  teamLeftClassName
                )}
              />
              <div className="flex items-center justify-center">
                <span className="flex flex-1 ml-1 -rotate-90 bg-white sm:text-[16px] text-[14px]">
                  {teamLeftName}
                </span>
              </div>
              <div
                className={twMerge(
                  `flex flex-1 w-2 rounded-full bg-black border-1 border-black`,
                  teamLeftClassName
                )}
              />
            </div>
          )}

          <div
            ref={containerRef}
            className="flex items-center justify-center w-full"
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `auto repeat(10, ${squareSize}px)`,
                gap: '2px'
              }}
            >
              {/* Empty top-left cell */}
              <div></div>

              {/* Top row with numbers 0 to 9 */}
              {topRowKeys.map((v) => (
                <div
                  key={`top-${v}`}
                  className="flex justify-center items-center sm:text-[18px] text-[14px] mb-2 font-bold"
                >
                  {!showNumbers ? '' : v}
                </div>
              ))}

              {/* Left column with numbers 0 to 9 and the grid of squares */}
              {leftRowKeys.map((rowIndex) => (
                <React.Fragment key={`row-${rowIndex}`}>
                  {/* Left column numbers */}
                  <div className="flex justify-center items-center mr-3 sm:text-[18px] font-bold text-[14px]">
                    {!showNumbers ? '' : rowIndex}
                  </div>

                  {/* Row of squares */}
                  {topRowKeys.map((colIndex) => (
                    <SquaresItem
                      key={`square-${rowIndex}-${colIndex}`}
                      rowIndex={rowIndex}
                      colIndex={colIndex}
                      squareSize={squareSize}
                      onSquareClick={onSquareClick}
                      selectedSquares={selectedSquares}
                      currentUser={currentUser}
                      disableClick={disableClick}
                      showWinners={showWinners}
                      quarter={quarter}
                      winners={winners}
                    />
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const findQuarter = (currentPeriod) => {
  switch (currentPeriod) {
    case 'Final':
    case 'Final/OT':
      return 5
    case 'End of 4th':
    case '4th':
      return 4
    case 'End of 3rd':
    case '3rd':
      return 3
    case 'Halftime':
    case 'halftime':
    case 'End of 2nd':
    case '2nd':
      return 2
    default:
    case 'End of 1st':
    case '1st':
      return 1
  }
}

export default SquaresTable
