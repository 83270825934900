import React, { memo, useEffect, useMemo, useState } from 'react'
import Base from './base'
import SelectItems from './select-items'
import { useParams, useNavigate } from 'react-router-dom'
import { database, ref } from '../firebase'
import RSVPPlaylist from './RSVP/Playlist/RSVPPlaylist'
import { useObject } from 'react-firebase-hooks/database'
import querystring from 'query-string'
import ClipLoader from 'react-spinners/ClipLoader'
import PhoneOrEmailVerificationModal from '../components/NewGuest/PhoneOrEmailVerificationModal/PhoneOrEmailVerificationModal'
import SquaresGuestChooseView from './Squares/SquaresGuestChoose/SquaresGuestChooseView'

const YesResponse = () => {
  const { circleCode, rsvpId } = useParams()
  const [infoToVerify, setInfoToVerify] = useState(null)
  const [phoneOrEmailModal, setPhoneOrEmailModal] = useState(false)
  const navigate = useNavigate()
  const params =
    typeof window != null ? querystring.parse(window.location.search) : {}

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId || '1'}`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])
  const eventInfo = eventSnap?.val()
  const rsvpInfo = rsvpSnap?.val()
  // FEATURE: Super Bowl
  const hasGame = false // !!eventInfo?.squaresGameId
  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  const onFinish = () => {
    navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
  }

  useEffect(() => {
    if (!eventLoading && !rsvpLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        (!rsvpInfo.phone && !rsvpInfo.email) ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!rsvpInfo.verified) {
        setPhoneOrEmailModal(true)
        setInfoToVerify(
          !!rsvpInfo.email
            ? { info: rsvpInfo.email, channel: 'email' }
            : { info: rsvpInfo.phone, channel: 'sms' }
        )
        return
      }
      setInfoToVerify(null)
      if (!rsvpInfo.response) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}`, {
          replace: true
        })
        return
      }
      if (rsvpInfo.response !== 'yes') {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`, {
          replace: true
        })
        return
      }
    }
  }, [
    eventLoading,
    rsvpLoading,
    eventInfo,
    rsvpInfo,
    circleCode,
    rsvpId,
    navigate
  ])

  if (eventLoading || rsvpLoading || guestsLoading) {
    return (
      <Base hideBg={true} bottomBar={false} allowFullHeight={true} whiteLogo>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true} whiteLogo>
      <div className="flex flex-1 flex-col md:items-stretch">
        {(!params.view || params.view === 'game') && !!hasGame ? (
          <SquaresGuestChooseView
            step
            hasMusic={hasMusic}
            hasRequestList={hasRequestList}
          />
        ) : params.view === 'music' && hasMusic ? (
          <RSVPPlaylist />
        ) : params.view === 'list' && hasRequestList ? (
          <SelectItems
            hasMusic={hasMusic}
            onFinish={onFinish}
            eventInfo={eventInfo}
            guestsInfo={guestsInfo}
          />
        ) : null}
      </div>

      <PhoneOrEmailVerificationModal
        opened={phoneOrEmailModal}
        circleCode={circleCode}
        rsvpId={rsvpId}
        infoToVerify={infoToVerify}
        onClose={() => {
          setPhoneOrEmailModal(false)
          setInfoToVerify(null)
        }}
      />
    </Base>
  )
}

export default memo(YesResponse)
